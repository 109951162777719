/* eslint-disable no-param-reassign */
import axios from 'axios'
import endpoints from '@/http/endpoints'

// #region Axios
const baseURL = process.env.VUE_APP_CHATAPI_URL
const timeout = process.env.VUE_APP_TIMEOUT

axios.defaults.trailingSlash = false

let isRefreshing = false
let refreshSubscribers = []

const axiosIns = axios.create({
  baseURL,
  timeout,
})

axiosIns.interceptors.request.use(
  config => {
    const token = localStorage.getItem('socketToken')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
      config.headers['Content-Type'] = 'application/json;charset=UTF-8'
      config.headers['Access-Control-Allow-Origin'] = '*'
      config.headers['Accept-Language'] = 'en'
    } else {
      config.headers.Authorization = ''
    }
    return config
  },
  error => Promise.reject(error),
)

axiosIns.interceptors.response.use(
  response => response,
  error => {
    const originalRequest = error.config
    if (error && error.response && (error.response.status === 401 || error.response.headers['Token-Expired'])) {
      if (!isRefreshing) {
        isRefreshing = true
        localStorage.removeItem('socketToken')
        localStorage.removeItem('socketId')
        const token = localStorage.getItem('accessToken')
        axiosIns.post(baseURL + endpoints.chatManagement.Controller.generateRefreshToken(), { token: token })
          .then(response => {
            const newToken = response.data.body.token
            const tokenId = response.data.body.id
            localStorage.setItem('socketToken', newToken)
            localStorage.setItem('socketId', tokenId)
            originalRequest.headers.Authorization = `Bearer ${newToken}`

            refreshSubscribers.forEach(subscriber => subscriber(newToken))
            refreshSubscribers = []
            return axiosIns(originalRequest)
          })
          .catch(err => Promise.reject(err))
          .finally(() => {
            isRefreshing = false
          })
      }

      return new Promise(resolve => {
        refreshSubscribers.push(token => {
          originalRequest.headers.Authorization = `Bearer ${token}`
          resolve(axiosIns(originalRequest))
        })
      })
    }
    return Promise.reject(error)
  },
)

// #endregion
export default {
  // #region Communication
  async getRoomList() {
    const promise = new Promise((resolve, reject) => {
      axiosIns.get(baseURL + endpoints.chatManagement.Controller.getRoomList())
        .then(response => {
          resolve({ data: response.data.body })
        })
        .catch(error => reject(error))
    })
    promise.then(result => result, error => error)
    return promise
  },
  async getRoomUserByRoomIdAndUserId(data) {
    const result = await axiosIns.get(baseURL + endpoints.chatManagement.Controller.getRoomUserByRoomIdAndUserId(data.roomId, data.userId))
    return result.data.body
  },
  async getSocketToken() {
    const promise = new Promise((resolve, reject) => {
      const token = localStorage.getItem('accessToken')
      if (token) {
        axiosIns.post(baseURL + endpoints.chatManagement.Controller.generateSocketToken(), { token: token })
          .then(response => {
            resolve({ data: response.data.body })
          })
          .catch(error => reject(error))
      }
    })
    promise.then(result => result, error => error)
    return promise
  },
  async createRoom(data) {
    const result = await axiosIns.post(baseURL + endpoints.chatManagement.Controller.createRoom(), data)
    return result
  },
  async deleteRoom(data) {
    const result = await axiosIns.delete(baseURL + endpoints.chatManagement.Controller.deleteRoom(data))
    return result
  },

  // #endregion

  // #region Room User Management Endpoints
  async addUser(data) {
    const result = await axiosIns.post(baseURL + endpoints.chatManagement.Controller.addUser(), data)
    return result
  },
  // #endregion

  // #region Message Management Endpoints
  async getMessages(data) {
    const result = await axiosIns.get(baseURL + endpoints.chatManagement.Controller.getMessages(data.roomId))
    return result.data.body
  },
  async sendMessage(data) {
    const result = await axiosIns.post(baseURL + endpoints.chatManagement.Controller.sendMessage(), data)
    return result.data.body
  },
  async updateMessage(id, data) {
    const result = await axiosIns.put(baseURL + endpoints.chatManagement.Controller.updateMessage(id), data)
    return result.data.body
  },
  async deleteMessage(id) {
    const result = await axiosIns.delete(baseURL + endpoints.chatManagement.Controller.deleteMessage(id))
    return result.data.body
  },
  async markMessageSeen(id) {
    const result = await axiosIns.get(baseURL + endpoints.chatManagement.Controller.markMessageSeen(id))
    return result.data.body
  },
  async getUnReadCount() {
    const result = await axiosIns.get(baseURL + endpoints.chatManagement.Controller.getUnReadCount())
    return result.data.body
  },
  // #endregion
}
