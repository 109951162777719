<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <!-- begin user invate section -->
        <div v-if="addNewRoom" class="form-row row mb-1">
          <div v-if="userCompanyType === 'warehouse'" class="col-2">
            <input
              v-model="accountNo"
              type="text"
              placeholder="Account No"
              class="form-control"
              @keyup.enter="onChangeAccountNo"
            >
          </div>
          <div :class="userCompanyType === 'warehouse' ? 'col-6' : 'col-8'">
            <dx-util-select-box
              id="printer-select-box"
              v-model="invitedUserId"
              :data-source="invatedUsers"
              display-expr="name"
              value-expr="id"
            />
          </div>
          <div class="col-2">
            <button class="btn btn-primary w-100" type="submit" :disabled="disableForm || !invitedUserId" @click="createRoom">
              Chat
            </button>
          </div>
          <div class="col-2">
            <button class="btn btn-danger w-100" @click="cancelNewRoom">
              Cancel
            </button>
          </div>
        </div>
        <!-- end user invate section -->
        <!-- begin user invate section -->
        <div v-if="addNewUser && userCompanyType === 'warehouse'" class="form-row row mb-1">
          <div class="col-2">
            <input
              v-model="accountNo"
              type="text"
              placeholder="Account No"
              class="form-control"
              @keyup.enter="onChangeAccountNo"
            >
          </div>
          <div class="col-6">
            <select v-model="invitedUserId" class="form-select">
                <option value="null">
                  Select User
                </option>
                <option v-for="item in invatedUsers" :key="item.id" :value="item.id">
                  <div class="d-flex flex-coloumn align-items-center">
                    <span>{{ item.name }}</span>
                    <span>{{ item.name }}</span>
                  </div>
                </option>
              </select>
          </div>
          <div class="col-2">
            <button class="btn btn-primary w-100" type="submit" :disabled="disableForm || !invitedUserId" @click="addRoomUser">
              Invite
            </button>
          </div>
          <div class="col-2">
            <button class="btn btn-danger w-100" @click="cancelNewRoom">
              Cancel
            </button>
          </div>
        </div>
        <!-- end user invate section -->
        <!-- begin chat section -->
        <vue-advanced-chat
        ref="chatWindow"
        :height="screenHeight"
        :theme="theme"
        :styles="JSON.stringify(styles)"
        :current-user-id="currentUserId"
        :room-id="roomId"
        :rooms="JSON.stringify(loadedRooms)"
        :loading-rooms="loadingRooms"
        :rooms-loaded="roomsLoaded"
        :room-info-enabled="roomInfoEnabled"
        :messages="JSON.stringify(messages)"
        :messages-loaded="messagesLoaded"
        :room-message="roomMessage"
        :room-actions="JSON.stringify(roomActions)"
        :menu-actions="JSON.stringify(menuActions)"
        :message-selection-actions="JSON.stringify(messageSelectionActions)"
        :templates-text="JSON.stringify(templatesText)"
        :text-messages="JSON.stringify(textMessages)"
        :show-send-icon="showSendIcon"
        :show-files="showFiles"
        :show-audio="showAudio"
        :show-reaction-emojis="showReactionEmojis"
        :responsive-breakpoint="responsiveBreakpoint"
        @fetch-more-rooms="fetchMoreRooms"
        @fetch-messages="fetchMessages($event.detail[0])"
        @send-message="sendMessage($event.detail[0])"
        @edit-message="editMessage($event.detail[0])"
        @delete-message="deleteMessage($event.detail[0])"
        @open-file="openFile($event.detail[0])"
        @open-user-tag="openUserTag($event.detail[0])"
        @add-room="addRoom($event.detail[0])"
        @room-action-handler="menuActionHandler($event.detail[0])"
        @menu-action-handler="menuActionHandler($event.detail[0])"
        @message-selection-action-handler="messageSelectionActionHandler($event.detail[0])"
        @send-message-reaction="sendMessageReaction($event.detail[0])"
        @typing-message="typingMessage($event.detail[0])"
        @toggle-rooms-list="$emit('show-demo-options', $event.detail[0].opened)"
      >
      </vue-advanced-chat>
        <!-- end chat section -->
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint arrow-body-style: ["error", "always"] */
/* eslint-env es6 */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */

import { register } from 'vue-advanced-chat'
import Pager from '@core/dev-extreme/utils/pager'
import companyService from '@/http/requests/company/companyService'
import chatApi from '@/http/requests/chat/chatManagement'
import chatService from '@/http/requests/chat/chatService'
import { Notify } from '@robustshell/utils/index'
import useCurrentUser from '@/libs/app/current-user'
import { ref } from '@vue/composition-api'
import { formatTimestamp, parseTimestamp } from './dates'

register()

export default {
  props: {
    breakpoint: {
      type: String,
      required: false,
      default: '900',
    },
    isOpened: {
      type: Boolean,
      required: false,
    },
    theme: {
      type: String,
      required: false,
      default: 'light',
    },
    messageListenner: {
      type: Object,
      required: false,
      default: () => { return {} },
    },
    roomListenner: {
      type: Object,
      required: false,
      default: () => { return {} },
    },
  },
  setup() {
    const { userId, userCompanyType, userAccountNo } = useCurrentUser()
    const currentUserId = ref(userId.value)
    return {
      userCompanyType,
      currentUserId,
      userAccountNo,
    }
  },
  data() {
    return {
      accountNo: '',
      invatedUsers: [],
      invitedUserId: null,
      addNewUser: false,
      // chat
      roomsPerPage: 15,
      rooms: [],
      roomId: '',
      // startRooms: null, // for pagination
      // endRooms: null,   // for pagination
      roomsLoaded: false,
      loadingRooms: true,
      allUsers: [],
      loadingLastMessageByRoom: 0,
      roomsLoadedCount: 0,
      selectedRoom: null,
      messagesPerPage: 20,
      messages: [],
      messagesLoaded: false,
      roomMessage: '',
      lastLoadedMessage: null,
      previousLastLoadedMessage: null,
      roomsListeners: [],
      listeners: [],
      typingMessageCache: '',
      disableForm: false,
      addNewRoom: false,
      addRoomUsername: '',
      inviteRoomId: null,
      removeRoomId: null,
      removeUserId: '',
      removeUsers: [],
      roomInfoEnabled: false,
      roomActions: [
        { name: 'deleteRoom', title: 'Delete Room' },
      ],
      menuActions: [
        { name: 'inviteUser', title: 'Invite User' },
        { name: 'removeUser', title: 'Remove User' },
        { name: 'deleteRoom', title: 'Delete Room' },
      ],
      messageSelectionActions: [{ name: 'deleteMessages', title: 'Delete' }],
      styles: { container: { borderRadius: '4px' } },
      templatesText: [
        {
          tag: 'help',
          text: 'This is the help',
        },
      ],
      textMessages: {
        IS_TYPING: '',
        LAST_SEEN: '',
        IS_ONLINE: '',
      },
      responsiveBreakpoint: 900,
      showSendIcon: 'false',
      showFiles: 'false',
      showAudio: 'false',
      showReactionEmojis: 'false',
    }
  },
  computed: {
    loadedRooms() {
      return this.rooms
    },
    screenHeight() {
      return this.isDevice ? `${window.innerHeight}px` : 'calc(100vh - 120px)'
    },
  },
  watch: {
    isOpened(val) {
      if (val) {
        this.fetchRooms()
        this.getCompanyUsers()
      } else {
        this.resetRooms()
        this.resetMessages()
      }
    },
    messageListenner: {
      handler(val) {
        if (val) {
          this.listenMessages(val)
        }
      },
      deep: true,
      immediate: true,
    },
    roomListenner: {
      handler(val) {
        if (val) {
          this.fetchRooms(val)
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    // local actions
    async onChangeAccountNo() {
      if (this.accountNo) {
        await this.getUser()
      }
    },
    async getCompanyUsers() {
      if (this.userCompanyType === 'warehouse') return
      const result = await chatService.findContactUsersByWarehouseId(this.currentUserId, this.userAccountNo)
      this.invatedUsers.length = 0
      result.forEach(item => {
        const user = {
          id: item.userId,
          name: item.name,
          avatar: item.imageUrl,
        }
        this.invatedUsers.push(user)
      })
    },
    async getUser() {
      companyService.fetchByAccountNo(this.accountNo)
        .then(result => {
          return result.data.id
        })
        .then(async id => {
          const pager = new Pager({})
          const userList = []
          const users = await companyService.fetchUsersByCompanyId(id, pager.staticPageable)
          users.data.content.forEach(item => {
            const user = {
              id: item.id,
              name: item.fullName,
              avatar: item.imageUrl,
            }
            userList.push(user)
          })
          return userList
        })
        .then(result => {
          this.invatedUsers.length = 0
          this.invatedUsers = result
        })
        .catch(error => {
          Notify.error(error.response.data.message)
        })
    },
    // chat actions
    resetRooms() {
      this.loadingRooms = true
      this.loadingLastMessageByRoom = 0
      this.roomsLoadedCount = 0
      this.rooms = []
      this.roomsLoaded = true
      // this.startRooms = null // for pagination
      // this.endRooms = null   // for pagination
      this.roomsListeners.forEach(listener => { return listener() })
      this.roomsListeners = []
      this.resetMessages()
    },
    resetMessages() {
      this.messages = []
      this.messagesLoaded = false
      this.lastLoadedMessage = null
      this.previousLastLoadedMessage = null
      this.listeners.forEach(listener => { return listener() })
      this.listeners = []
    },
    fetchRooms() {
      this.resetRooms()
      this.fetchMoreRooms()
    },
    async fetchMoreRooms() {
      this.roomsLoaded = false
      this.loadingRooms = true
      const { data } = await chatApi.getRoomList()
      if (data.length !== 0) {
        this.roomsLoaded = true
        this.loadingRooms = false
      } else {
        this.roomsLoaded = true
        this.loadingRooms = false
        return
      }

      const roomList = data

      const formattedRooms = []
      Object.keys(roomList).forEach(key => {
        const room = roomList[key]
        const formatedUsers = room.users.map(user => {
          return {
            ...user,
            id: user.userId,
            _id: user.localUserId,
            status: {
              lastChanged: null,
            },
          }
        })
        room.users = formatedUsers
        const roomContacts = room.users.filter(user => { return user._id !== this.currentUserId.toString() })
        room.roomName = roomContacts.map(user => { return user.username }).join(', ') || 'Myself'
        const roomAvatar = roomContacts.length === 1 && roomContacts[0].avatar ? roomContacts[0].avatar : ''
        const roomCreatedAt = new Date(room.createdAt)
        const index = roomCreatedAt.getTime()
        formattedRooms.push({
          ...room,
          roomId: room.id,
          avatar: roomAvatar,
          index: index,
          lastMessage: {
            content: formatTimestamp(
              room.createdAt,
              room.createdAt,
            ),
            timestamp: '',
          },
        })
      })
      this.rooms = this.rooms.concat(formattedRooms)
      formattedRooms.forEach(room => { return this.listenLastMessage(room) })
    },
    listenLastMessage(room) {
    },
    formatLastMessage(message, room) {
      if (!message.timestamp) return null
      let content = message.content
      if (message.files?.length) {
        const file = message.files[0]
        content = `${file.name}.${file.extension || file.type}`
      }
      const username = message.sender_id !== this.currentUserId ? room.users.find(user => { return message.sender_id === user._id })?.username : ''
      const result = {
        ...message,
        ...{
          _id: message.id,
          content,
          senderId: message.sender_id,
          timestamp: formatTimestamp(
            new Date(message.timestamp.seconds * 1000),
            message.timestamp,
          ),
          username: username,
          distributed: true,
          seen: message.sender_id === this.currentUserId ? message.seen : null,
          new: message.sender_id !== this.currentUserId && (!message.seen || !message.seen[this.currentUserId]),
        },
      }
      return result
    },
    async fetchMessages({ room, options = {} }) {
      if (options.reset) {
        this.resetMessages()
      }
      if (this.previousLastLoadedMessage && !this.lastLoadedMessage) {
        this.messagesLoaded = true
        return
      }
      this.selectedRoom = room.roomId
      room.unreadCount = 0
      const data = await chatApi.getMessages({ roomId: room.roomId })
      this.messagesLoaded = true
      data.forEach(message => {
        const formattedMessage = this.formatMessage(room, message)
        this.messages.push(formattedMessage)
      })
    },
    listenMessages(message) {
      if (message) {
        const room = this.rooms.find(item => { return item.roomId === message.roomId })
        if (this.selectedRoom === message.roomId) {
          const formattedMessage = this.formatMessage(room, message)
          this.messages.push(formattedMessage)
        } else {
          room.unreadCount += 1
        }
      }
    },
    async markMessagesSeen(message) {
      if (message.senderId !== this.currentUserId && (!message.seen)) {
        await chatApi.markMessageSeen(message.id)
      }
    },
    formatMessage(room, message) {
      this.markMessagesSeen(message)
      const senderUser = room.users.find(user => { return user._id === message.senderId })
      const formattedMessage = {
        ...message,
        ...{
          _id: message.id,
          content: message.content,
          seconds: message.timestamp.seconds,
          timestamp: parseTimestamp(message.timestamp, 'HH:mm'),
          date: parseTimestamp(message.timestamp, 'DD MMMM YYYY'),
          username: room.users.find(user => { return message.senderId === user._id })?.username,
          avatar: senderUser ? senderUser.avatar : null,
          seen: message.senderId === this.currentUserId.toString() ? message.seen : null,
          distributed: true,
          typingUsers: [],
        },
      }

      if (message.replyMessage !== null && message.replyMessage !== undefined && message.replyMessage !== '') {
        formattedMessage.replyMessage = {
          ...message.replyMessage,
          ...{
            _id: message.replyMessage.id,
            content: message.replyMessage.content,
          },
        }
      }
      return formattedMessage
    },
    async sendMessage({
      content, roomId, files, replyMessage,
    }) {
      const message = {
        roomId: roomId,
        content: content,
      }
      if (files) {
        message.files = this.formattedFiles(files)
      }
      if (replyMessage) {
        message.replyMessage = {
          messageId: replyMessage._id,
          content: replyMessage.content,
          senderId: replyMessage.senderId,
        }
        if (replyMessage.files) {
          message.replyMessage.files = replyMessage.files
        }
      }
      const result = await chatApi.sendMessage(message)
      const room = this.rooms.find(item => { return item.roomId === roomId })
      const formattedMessage = this.formatMessage(room, result)
      this.messages.push(formattedMessage)
    },
    async editMessage({
      messageId, newContent, roomId, files,
    }) {
      if (messageId) {
        const result = await chatApi.updateMessage(messageId, {
          roomId: roomId,
          content: newContent,
        })
        const room = this.rooms.find(item => { return item.roomId === roomId })
        const formattedMessage = this.formatMessage(room, result)
        this.messages = this.messages.map(message => {
          if (message._id === messageId) {
            return formattedMessage
          }
          return message
        })
      }
    },
    async deleteMessage({ message, roomId }) {
      await chatApi.deleteMessage(message.id)
      this.messages = this.messages.filter(item => { return item._id !== message._id })
    },
    async openUserTag({ user }) {
      // console.log('openUserTag', user)
    },
    async loadRoom(query) {
      query.forEach(async room => {
        if (this.loadingRooms) return
        // update lastUpdated to trigger room update
        this.roomId = room.id
        this.fetchRooms()
      })
    },
    menuActionHandler({ action, roomId }) {
      switch (action.name) {
        case 'inviteUser':
          this.inviteUser(roomId)
          break
        case 'removeUser':
          this.removeUser(roomId)
          break
        case 'deleteRoom':
          this.deleteRoom(roomId)
          break
        default:
          break
      }
    },
    messageSelectionActionHandler({ action, messages, roomId }) {
      switch (action.name) {
        case 'deleteMessages':
          messages.forEach(message => {
            this.deleteMessage({ message, roomId })
          })
          break
        default:
          break
      }
    },
    async sendMessageReaction({
      reaction, remove, messageId, roomId,
    }) {
      const message = {
        roomId: roomId,
        messageId: messageId,
        reaction: reaction,
        remove: remove,
      }
      // await chatApi.sendMessageReaction(message)
    },
    typingMessage({ message, roomId }) {
      if (roomId) {
        // console.log('typingMessage', message, roomId)
      }
    },
    async listenRooms(query) {
      // console.log('listenRooms', query)
    },
    listenUsersOnlineStatus(rooms) {
      // console.log('listenUsersOnlineStatus', rooms)
    },
    addRoom() {
      this.resetForms()
      this.addNewRoom = true
    },
    async createRoom() {
      this.disableForm = true
      const data = {
        roomId: '',
        senderId: this.currentUserId.toString(),
        receiverId: this.invitedUserId.toString(),
      }
      await chatApi.createRoom(data)
      this.addNewRoom = false
      this.invitedUserId = null
      this.fetchRooms()
    },
    inviteUser(roomId) {
      this.resetForms()
      this.addNewUser = true
      this.inviteRoomId = roomId
    },
    async addRoomUser() {
      this.disableForm = true
      const data = {
        roomId: this.inviteRoomId,
        senderId: this.currentUserId.toString(),
        receiverId: this.invitedUserId.toString(),
      }
      await chatApi.addUser(data)
      this.addNewUser = false
      this.fetchRooms()
    },
    removeUser(roomId) {
      this.resetForms()
      this.removeRoomId = roomId
      this.removeUsers = this.rooms.find(room => { return room.roomId === roomId }).users
    },
    async deleteRoomUser() {
      this.fetchRooms()
    },
    async deleteRoom(roomId) {
      const room = this.rooms.find(r => { return r.roomId === roomId })
      if (room.users.length > 2) {
        this.removeUser(roomId)
      } else {
        await chatApi.deleteRoom(roomId)
      }
      this.fetchRooms()
    },
    resetForms() {
      this.disableForm = false
      this.addNewRoom = null
      this.addRoomUsername = ''
      this.inviteRoomId = null
      this.invitedUserId = null
      this.removeRoomId = null
      this.removeUserId = ''
    },
    cancelNewRoom() {
      this.resetForms()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-chat.scss';
@import '~@core/scss/base/pages/app-chat-list.scss';
.chat-body {
  width: 100%;
}
</style>
