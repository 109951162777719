/* eslint-disable consistent-return */
import moment from 'moment'

export const parseTimestamp = (timestamp, format = '') => {
  if (!timestamp) return
  const date = moment(timestamp).format(format)
  return date
}

export const formatTimestamp = (date, timestamp) => {
  const dateFormated = moment(date)
  const timestampFormated = moment(timestamp)
  const timestampFormat = dateFormated.isSame(timestampFormated, 'day') ? 'HH:mm' : 'MM/DD/YYYY'
  const result = timestampFormated.format(timestampFormat)
  return timestampFormat === 'HH:mm' ? `Today, ${result}` : result
}

export const formatDate = date => moment(date).toLocaleString()
